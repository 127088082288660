/*
 * A simple fadein for the nodes
 * Applied as css to allow targeting the svg edges created by reactflow
 * The 15% keyframe creates a pause which prevents a flash as the node renders
 */

@keyframes fadein {
  0% { opacity:0; }
  15% { opacity:0; }
  100% { opacity:1; }
}

.react-flow__node,
.react-flow__edge {
  animation: fadein 0.5s ease-in-out;
}
